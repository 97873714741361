<template>
  <div>
    <!--begin::tax-->
    <div>
      <h3 class="card-label">
        {{ $t('tax.tax_management') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <div class="row p-0">
        <div class="col-md-12">
          <div class="d-flex justify-content-end mt-2 p-2 bg-FFB803" style="height: 45px;">
            <button type="button" v-if="!this.idEdit" class="btn btn-primary btn-sm" @click="addTax">{{ $t('add_more') }}</button>
          </div>
        </div>

        <div class="col-md-12">
          <div class="bg-white">
<!--            <table class="table table-row-bordered table-custom-padding" @keyup.enter="addTax" @keyup.46="removeRowTax(0)">-->
            <table class="table table-row-bordered table-custom-padding" >
              <thead>
              <tr>
                <th style="width:25%">{{ $t('tax.tax_name') }}</th>
                <th style="width:10%">{{ $t('tax.value_rate') }}%</th>
                <th style="width:15%">{{ $t('tax.include_tax') }}</th>
                <th style="width:15%">{{ $t('tax.type') }}</th>
                <th style="width:15%">{{ $t('tax.tax_rule') }}</th>
                <th style="width:15%">{{ $t('tax.rule_option_value') }}</th>
                <th style="width:15%">{{ $t('minimum') }}</th>
                <th style="width:15%">{{ $t('is_default') }}</th>

                <th style="width:15%">{{ $t('tax.status') }}</th>
<!--                <th style="width:15%">{{ $t('tax.description') }}</th>-->
                <th style="width:15%"></th>
              </tr>

              </thead>
              <tbody>
              <tr v-for="(row, index) in taxes" :key="index">
                <td>
                  <input type="text" v-model="row.tax_name" class="form-control" :class="validation && validation[index+'.tax_name'] ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation[index+'.tax_name']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.tax_name'][0] }}
                                    </span>
                </td>
                <td>
                  <input type="number" step="1.0" v-model="row.value_rate" class="form-control" :class="validation && validation[index+'.value_rate'] ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation[index+'.value_rate']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.value_rate'][0] }}
                                    </span>
                </td>
                <td>
                  <select name="" v-model="row.include_tax" class="custom-select" :class="validation && validation[index+'.include_tax'] ? 'is-invalid' : ''">
                    <option v-for="(include, index) in include_list" :value="index" :key="index">
                      {{ include.name }}
                    </option>
                  </select>
                  <span v-if="validation && validation[index+'.include_tax']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.include_tax'][0] }}
                                    </span>
                </td>
                <td>
                  <select name="" v-model="row.type" class="custom-select" :class="validation && validation[index+'.type'] ? 'is-invalid' : ''">
                    <option v-for="(row, index) in type_list" :value="row.id" :key="'type'+index">
                      {{ row.title }}
                    </option>
                  </select>
                  <span v-if="validation && validation[index+'.include_tax']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.include_tax'][0] }}
                                    </span>


                <td>
                  <select name="" v-model="row.tax_rule_id" class="custom-select" @input="getDelimiters" :class="validation && validation[index+'.tax_rule_id'] ? 'is-invalid' : ''">
                    <option v-for="(row, index) in tax_rules_lists" :value="row.id" :key="'tax_rule_'+index">
                      {{ row.name }}
                    </option>
                  </select>
                  <span v-if="validation && validation[index+'.tax_rule_id']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.tax_rule_id'][0] }}
                                    </span>
                </td>

                <td>
                  <select name="" v-model="row.rule_option_value" class="custom-select" :class="validation && validation[index+'.rule_option_value'] ? 'is-invalid' : ''">
                    <option v-for="(row, index) in delimiters_lists" :value="row.value" :key="'delimiters_'+index">
                      {{ row.name }}
                    </option>
                  </select>
                  <span v-if="validation && validation[index+'.rule_option_value']" class="fv-plugins-message-container invalid-feedback">
                                      {{ validation[index + '.rule_option_value'][0] }}
                                    </span>
                </td>






                <td>
                  <input type="number" v-model="row.minimum" class="form-control" :placeholder="$t('minimum')"/>

                </td>
                <td>
                  <b-form-checkbox size="lg" v-model="row.is_default" name="check-button" switch></b-form-checkbox>

                </td>
                <td>
                  <b-form-checkbox size="lg" v-model="row.is_active" name="check-button" switch></b-form-checkbox>
<!--                  <select name="" v-model="row.is_active" class="custom-select" :class="validation && validation[index+'.is_active'] ? 'is-invalid' : ''">-->
<!--                    <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>-->

<!--                  </select>-->
<!--                  <span v-if="validation && validation[index+'.is_active']" class="fv-plugins-message-container invalid-feedback">-->
<!--                                      {{ validation[index + '.is_active'][0] }}-->
<!--                                    </span>-->
                </td>

<!--                <td>-->
<!--                  <textarea v-model="row.description" class="form-control" :class="validation && validation[index+'.description'] ? 'is-invalid' : ''"></textarea>-->
<!--                  <span v-if="validation && validation[index+'.description']" class="fv-plugins-message-container invalid-feedback">-->
<!--                                      {{ validation[index + '.description'][0] }}-->
<!--                                    </span>-->
<!--                </td>-->

                <td>
                  <v-icon style="color: #dc3545;" small @click="removeRowTax(index)">mdi-delete</v-icon>
                  <!--                                    <a href="javascript:;" @click="removeRowTax(index)" class="btn btn-danager btn-icon btn-active-light-primary w-30px h-30px me-3">-->
                  <!--                                        {{ $t('delete') }}-->
                  <!--                                    </a>-->
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>

      </div>
      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save()">
              {{ $t('save') }}
            </button>
          </div>
        </div>
      </div>

    </div>
    <!--end::tax-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "tax-form",
  data() {
    return {
      isEditing: false,
      idEdit: this.$route.params.id ? this.$route.params.id : '',
      mainRouteDependency: 'base/dependency',

      include_list: [
        {id: 1, name: this.$t("yes")},
        {id: 0, name: this.$t("no")},
      ],
      taxes: [],
      type_list: [],
      tax_from: {id: null, tax_name: null, value_rate: null, type: null, include_tax: null, is_active: true, is_default: false, minimum: 0, tax_rule_id: null, rule_option_value: null,},
      validation: null,
      tax_rules_lists: [],
      delimiters_lists: [],
      mainRoute: 'settings/taxes',
      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],

    };
  },
  watch: {
    // taxes: {
    //     handler(val) {
    //         let _arr = [];
    //         if (val && val.length)
    //             val.forEach((row) => {
    //                 if (row.tax)
    //                     _arr.push(row.tax.id);
    //             });
    //         if (this.taxesList.length)
    //             this.innerTaxesList = this.taxesList.filter(row => !_arr.includes(row.id));
    //     },
    //     deep: true,
    // },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.taxes,
      })
          .then((response) => {

            this.validation = null;
            this.$router.push({name: 'taxes.index'});

            this.$successAlert(response.data.message)
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
            this.$errorAlert(error);
          });
    },

    update() {
      ApiService.put(`${this.mainRoute}/${this.idEdit}`, {
        ...this.taxes,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'taxes.index'});
            this.$successAlert(response.data.message)
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
            this.$errorAlert(error);
          });
    },

    addTax() {
      this.taxes.unshift(this.tax_from);
      this.tax_from = {id: null, tax_name: null, value_rate: null, include_tax: null, is_active: true, is_default: false, minimum: 0, tax_rule_id: null, rule_option_value: null};
    },

    removeRowTax(index) {
      this.taxes.splice(index, 1);
    },
    getDelimiters: _.debounce(function (event){
      let _id = (event && event.target && event.target.value) ? event.target.value : (event ? event : null);
      if (_id){
        let _diam = this.tax_rules_lists.find((row) => row.id == _id);
        if (_diam){
          this.delimiters_lists = (_diam.tax_rule_details[0] && _diam.tax_rule_details[0].options)? _diam.tax_rule_details[0].options : [];
        }else {
          this.delimiters_lists = [];
        }
      }

    }, 100),
    async getData() {
      ApiService.get(`${this.mainRoute}/${this.idEdit}`).then((response) => {
        this.isEditing = true;
        this.taxes = [response.data.data];
        if (response.data.data.tax_rule_id){
          this.getDelimiters(response.data.data.tax_rule_id);
        }

      });
    },
    getTypes() {
      ApiService.get(this.mainRouteDependency + "/tax_type_list").then((response) => {
        this.type_list = response.data.data;
      });
    },

    async getTaxesRulesForTaxSetting() {
      await ApiService.get(this.mainRouteDependency + "/taxes_rules_for_tax_setting/1").then((response) => {
        this.tax_rules_lists = response.data.data;

      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.tax_Management"), route: '/settings/taxes'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

    this.getTypes();
    let promise = this.getTaxesRulesForTaxSetting();

    Promise.all([promise]).then(()=>{
      if (this.idEdit) {
        this.getData();
      }
    })

    if (!this.idEdit && this.taxes.length <= 0) {
      this.addTax();

    }
  },
};
</script>


